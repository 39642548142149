import { bind, classNames, Component, computed, h, OmiProps, signal, tag } from "omi";
import htmx from "htmx.org";

import globalStyles from "./globals/styles.css?inline";
import styles from "./app.css?inline";

import { Project } from "@/api/models/project";
import { tailwind } from "@/tailwind";
import {
  MarkerSelectedEvent,
  NWBlock,
  NWButton,
  NWContentMenu,
  NWIcon,
  NWLoadingSpinner,
  NWMetabox,
  NWMissionBtn,
  NWNav,
  NWProgressBar,
  MissionButtonState,
  NWToolbar,
  openModal,
  NWBadge,
  NWError,
  NWTrackManager,
} from "@/components";
import { initApi, isMobileDevice, openAllLinksInNewTab } from "@/utils";
import { DefaultApi, Track, Validation, ValidationStatus, ProjectStatus } from "@/api";
import { networkSignal } from "@/utils/signals";
import { Store } from "@/store";
import { TrackEvents, TrackLoadedEvent } from "@/components/nw-track/events";
import { CompletedEvent, ValidationEvents } from "@/components/validations/events";
import { ErrorService } from "@/services/errorService";
import { ScrollService } from "@/services/scrollService";
import { NWModalProvider } from "@/components/modals";
import { AuthEvents } from "@/components/nw-auth-manager";
import { NWToastManager, triggerToast } from "@/components/base/nw-toast";
import { triggerNewFeatureToast } from "@/utils/featureRefresh";
import { PurchaseNotificationService } from "@/services/purchaseNotificationService";
import { getFromQueryString } from "@/utils/url.ts";
import { AUTH_LOGGED_IN_TOAST_TRIGGER } from "@/globals/storageKeys.ts";
import isEmpty from "lodash-es/isEmpty";

type Props = {
  project: Required<Project>;
  selectedTrack?: Track["id"];
};

@tag("project-app")
export default class extends Component<Props> {
  static css = [tailwind, globalStyles, styles];
  static propTypes = {
    project: Object,
    selectedTrack: String,
  };

  private api: DefaultApi = initApi();

  private scrollService: ScrollService | undefined;
  private purchaseNotificationService: PurchaseNotificationService | undefined;

  private isIntroLoaded = signal<boolean>(false);
  private selectedTrack = signal<Track["id"]>(undefined);
  private completedValidations = signal(new Set<string>());

  private validations = networkSignal<Validation[]>({ default: [] });
  private projectUpdate = networkSignal<Project>();

  private isProjectCompletable = computed(() => {
    const validations = this.validations.signal.data.value;

    if (validations && validations.length > 0) {
      const isFinished = validations.length === this.completedValidations.value.size;
      return isFinished;
    }

    // Default when there are no validations in a project.
    return true;
  });

  install() {
    this.attachShadow({ mode: "open" });
    Store.project.setProject(this.props.project);
  }

  installed() {
    // Allow HTMX to be used by this web component
    if (this.rootElement) {
      htmx.process(this.rootElement);

      this.rootElement.addEventListener("htmx:afterRequest", event => {
        const elementId = (event.target as Element)?.id;

        if (elementId === "intro-content") {
          this.isIntroLoaded.value = true;
          openAllLinksInNewTab(this.shadowRoot, true);
        }
      });
    }

    this.validations.run(async () => {
      const validations = await this.api.getProjectValidations({ projectId: Store.project.id.value || "" });
      this.completedValidations.value = this.getCompletedFromValidations(validations);
      return validations;
    });

    addEventListener(TrackEvents.TRACK_LOADED, this.handleTrackSelected as EventListener);
    addEventListener(ValidationEvents.COMPLETED, this.handleValidationCompleted as EventListener);
    addEventListener(ValidationEvents.EDITED, this.handleValidationEdited as EventListener);

    import("@/services/scrollService").then(({ ScrollService }) => {
      this.scrollService = new ScrollService();
      this.scrollService.startObservation();
    });

    if (Store.user.isLoggedIn.value) {
      ErrorService.setUser(Store.user.info.value?.email);
    }

    import("@/services/analyticsService").then(({ useAnalyticsService }) => {
      useAnalyticsService().trackProjectViewed(this.props.project.id);
    });
  }

  @bind
  private handleMobileExperience() {
    const hasNotSelectedTopics = isEmpty(Store.user.preferences.selectedCategories.value);
    const shouldTriggerLoggedInToast = getFromQueryString(AUTH_LOGGED_IN_TOAST_TRIGGER, true);

    if (Store.user.isLoggedIn.value) {
      if (shouldTriggerLoggedInToast && hasNotSelectedTopics) {
        setTimeout(() => {
          Store.app.toggleNav(true, "mobile-first-time");
        }, 100);
      } else if (shouldTriggerLoggedInToast) {
        setTimeout(
          () =>
            triggerToast({
              icon: "check-circle-outline-green",
              message: "Your logged in! Let’s learn 🎉",
              position: "top",
            }),
          500,
        );
      }
    } else {
      // Force login for mobile users
      openModal("login", {});
    }
  }

  @bind
  private handleDesktopExperience() {
    const shouldTriggerLoggedInToast = getFromQueryString(AUTH_LOGGED_IN_TOAST_TRIGGER, true);
    if (shouldTriggerLoggedInToast) {
      setTimeout(
        () =>
          triggerToast({
            icon: "check-circle-outline-green",
            message: "Your logged in! Let’s learn 🎉",
            position: "top",
          }),
        500,
      );
    }
  }

  ready() {
    if (isMobileDevice()) {
      this.handleMobileExperience();
    } else {
      this.handleDesktopExperience();
    }

    if (Store.features.hasFeature("projects.payments")) {
      import("@/services/purchaseNotificationService").then(({ PurchaseNotificationService }) => {
        this.purchaseNotificationService = new PurchaseNotificationService();
        this.purchaseNotificationService.process();
      });
    }

    setTimeout(() => triggerNewFeatureToast());
  }

  @bind
  private getCompletedFromValidations(validations: Validation[]): Set<string> {
    const completedValidations = new Set<string>();

    for (const { id, status } of validations) {
      if (status === ValidationStatus.Complete) {
        completedValidations.add(id!);
      }
    }

    return completedValidations;
  }

  @bind
  private handleTrackSelected(event: CustomEvent<TrackLoadedEvent>) {
    const trackId = event.detail.id;
    this.selectedTrack.value = trackId;
  }

  @bind
  private handleValidationCompleted(event: CustomEvent<CompletedEvent>) {
    const { id } = event.detail;
    this.completedValidations.value.add(id);
    this.completedValidations.update();
  }

  @bind
  private handleValidationEdited(event: CustomEvent<CompletedEvent>) {
    const { id } = event.detail;
    this.completedValidations.value.delete(id);
    this.completedValidations.update();

    if (Store.project.isComplete()) {
      this.setMissionComplete(false, true);
    }
  }

  @bind
  private async handleMissionComplete(): Promise<void> {
    if (!this.isProjectCompletable.peek() || Store.project.isComplete()) {
      return;
    }

    const wasSuccessful = await this.setMissionComplete(true);

    if (!wasSuccessful) {
      return;
    }

    openModal("share", {
      projectId: Store.project.id.value!,
      linkedInPostData: Store.project.metadata.value?.sharetemplate,
    });

    import("@/services/analyticsService").then(({ useAnalyticsService }) => {
      useAnalyticsService().trackProjectComplete(this.props.project.id);
    });
  }

  @bind
  private async setMissionComplete(state: boolean, updateImmediately: boolean = false): Promise<boolean> {
    const status = state ? ProjectStatus.PROJECT_COMPLETE : ProjectStatus.PROJECT_INCOMPLETE;

    const event = new CustomEvent("project_updated", { detail: { projectId: this.props.project.id, status } });
    dispatchEvent(event);

    if (updateImmediately) {
      // Used to bypass the waiting period for the project status when we want the UI to update immediately, i.e. editing a validation
      Store.project.status.value = status;
    }

    const res = await this.projectUpdate.run(
      async () =>
        await this.api.submitProjectUpdate({
          projectId: this.props.project.id,
          projectUpdate: { status },
        }),
    );

    if (res == null) {
      return false;
    }

    Store.project.setProject(res);
    return res.status === ProjectStatus.PROJECT_COMPLETE;
  }

  @bind
  private scrollToStep(event: MarkerSelectedEvent): void {
    const { stepId } = event.detail;
    const scrollToEvent = new CustomEvent("scroll_to_step", { detail: { stepId } });
    dispatchEvent(scrollToEvent);
  }

  private handleSignIn() {
    const loginEvent = new Event(AuthEvents.LoginClicked);
    dispatchEvent(loginEvent);
  }

  @bind
  private calculateMissionAccomplishedState(): MissionButtonState {
    if (this.isProjectCompletable.value && Store.project.isComplete()) {
      return MissionButtonState.Complete;
    }

    if (this.isProjectCompletable.value) {
      return MissionButtonState.Enabled;
    }

    return MissionButtonState.Disabled;
  }

  render(props: OmiProps<Props>) {
    const {
      project: { id, metadata },
      selectedTrack,
    } = props;
    const isLoggedIn = Store.user.isLoggedIn.value;
    const hasValidations = this.validations.signal.data.value?.length !== 0;
    const missionAccomplishedState = this.calculateMissionAccomplishedState();

    return (
      <div>
        <NWModalProvider />
        <NWToastManager />
        <NWProgressBar />

        <div id="project-layout" class="page-layout xl:gap-16 2xl:gap-20 relative mx-auto sm:py-8 sm:px-9">
          <div class={classNames({ "hidden xl:block": !Store.features.hasFeature("projects.navV2") })}>
            <NWNav />
          </div>

          <div>
            <NWBlock>
              <div class="flex flex-col items-center space-y-8 md:space-y-2">
                <NWBadge withIndicator>Project</NWBadge>

                <div class="flex flex-col md:flex-row justify-center items-center gap-6 text-center">
                  <img src={metadata.icon} class="max-w-16" />
                  <h1 class="flex content-center items-center self-stretch text-4xl font-semibold text-center">
                    {metadata.title}
                  </h1>
                </div>

                <p id="headline" class="flex-1 m-0 text-gray-900 text-center">
                  {metadata.description}
                </p>
              </div>
              <div class="flex py-12 mt-12 flex-col items-start gap-8 self-stretch border-y border-gray-200">
                <div class="flex flex-wrap items-start gap-x-8 gap-y-4 md:gap-12">
                  <NWMetabox label="DIFFICULTY" text={metadata.difficulty}>
                    <NWIcon slot="icon" name="terminal-square" />
                  </NWMetabox>
                  <NWMetabox label="TIME" text={metadata.time}>
                    <NWIcon slot="icon" name="hourglass" />
                  </NWMetabox>
                  <NWMetabox label="COST" text={metadata.cost}>
                    <NWIcon slot="icon" name="currency-dollar" />
                  </NWMetabox>
                </div>

                {metadata.needs && (
                  <div class="flex flex-col md:flex-row w-full justify-between items-start">
                    <NWMetabox label="WHAT YOU'LL NEED">
                      <NWIcon slot="icon" name="tool" />
                    </NWMetabox>

                    <div class="w-full md:w-[60%] my-2 md:my-0">
                      <ul class="list-outside">
                        {metadata.needs.map(value => (
                          <li class="text-gray-700 text-sm list-disc p-0" unsafeHTML={{ html: value }}></li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}

                {metadata.concepts && (
                  <div class="flex flex-col md:flex-row w-full justify-between items-start">
                    <NWMetabox label="AWS SERVICES">
                      <NWIcon slot="icon" name="amazon" />
                    </NWMetabox>

                    <div class="w-full md:w-[60%] my-2 md:my-0">
                      <ul class="list-outside">
                        {metadata.concepts.map(value => (
                          <li class="text-gray-700 text-sm list-disc p-0" unsafeHTML={{ html: value }}></li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
              </div>

              <div>
                <div id="intro-content" hx-get={`${id}/intro`} hx-trigger="load">
                  {!this.isIntroLoaded.value && <NWLoadingSpinner></NWLoadingSpinner>}
                </div>
              </div>

              {this.isIntroLoaded.value && (
                <div class="w-full">
                  <NWTrackManager selected={selectedTrack} tracks={metadata.tracks ?? []} />
                </div>
              )}

              {this.isIntroLoaded.value && this.selectedTrack.value != null && (
                <div class="my-10">
                  <NWMissionBtn
                    isLoading={this.projectUpdate.signal.isLoading.value}
                    state={missionAccomplishedState}
                    onClick={this.handleMissionComplete}
                    hasValidations={hasValidations}
                    disabledTooltip={`You still have tasks to complete, good luck 🙏`}
                  />
                  {this.projectUpdate.signal.error.value && (
                    <div class="mt-2">
                      <NWError />
                    </div>
                  )}
                </div>
              )}
            </NWBlock>

            <NWToolbar />
          </div>

          <div class="overflow-clip xl:max-w-md xl:min-w-48">
            <div class="hidden xl:block">
              {Store.features.hasFeature("projects.login") && !isLoggedIn && (
                <div class="absolute right-8">
                  <NWButton type="secondary" size="md" onClick={this.handleSignIn}>
                    Sign in
                  </NWButton>
                </div>
              )}
            </div>

            <NWContentMenu onMarkerSelected={this.scrollToStep} trackId={this.selectedTrack.value} />
          </div>
        </div>
      </div>
    );
  }
}
